import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Container,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  FormControl,
  Card,
  CardContent,
} from "@mui/material";
import "./style.css";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CssBaseline from "@mui/material/CssBaseline";
import KitchenType from "./Components/Step1";
import MaterialType from "./Components/Step2";
import FrameType from "./Components/Step3";
import GetEstimate from "./Components/Step4";
import { PartyMode } from "@mui/icons-material";
import ShoeRackType from "./Components/Step1";
import CarcaseMaterial from "./Components/Step3";
import DoorFinish from "./Components/Step2";

const ShoerackQuote = () => {
  const [activeStep, setActiveStep] = useState(0);
  const totalStep = 4;
  const [openModal, setOpenModal] = useState(false);
  // const [selectedKitchenType, setSelectedkitchenType] = useState(null);
  const [formData,setFormData]=useState({
    selectedRackType:null,
    selectedDoorFinishType:null,
    selectedCarcaseType:null,
    usermeasurements:null,
    adminmeasurements:null,
    mailform:null,
  })

  const [totalCost,setTotalCost]=useState(0)
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleShoeRackTypeChange = (RackType)=>{
    setFormData((prevData)=>({
    ...prevData,
    selectedRackType:RackType,
    }))
  }

  const handleMaterialLayoutChange = (MaterialType) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedCarcaseType:MaterialType,
    }));
  };
  const handleDoorFinishChange = (doorfinish) => {
    setFormData((prevData) => ({
      ...prevData,
     selectedDoorFinishType:doorfinish
    }));
  };
  const handleFormMail= (form) => {
    setFormData((prevData) => ({
      ...prevData,
      mailform: form,
    }));
  };
  const handleUserMeasurements = (userM)=>{
    setFormData((prevData)=>({
      ...prevData,
     usermeasurements:userM
    }))
  }
  const handleAdminMeasurements = (adminM)=>{
    setFormData((prevData)=>({
      ...prevData,
     adminmeasurements:adminM
    }))
  }
  const handlesubmit=()=>{
    // console.log(formData)
  }

 

  // console.log(formData,'hey')


  
  const getStepContent = (stepIndex) => {
    // Define your form components for each step
    switch (stepIndex) {
      case 0:
        return <ShoeRackType onSelectShoeRackType={handleShoeRackTypeChange} openModal={handleOpenModal} selectedShoeRackType={formData.selectedRackType}/>;
      case 1:
        return <DoorFinish onSelectdoorfinishType={handleDoorFinishChange} openModal={handleOpenModal} selecteddoorfinishType={formData.selectedDoorFinishType}/>;
      case 2:
        return <CarcaseMaterial onSelectMaterialType={handleMaterialLayoutChange}  openModal={handleOpenModal} selectedMaterialType={formData.selectedCarcaseType} onSelectUserMeasureMents={handleUserMeasurements} selectedUserM={formData.usermeasurements} onSelectAdminMeasurements={handleAdminMeasurements} selectedAdminM={formData.adminmeasurements} />
      case 3:
        return <GetEstimate OnSendFormData={handleFormMail} EnteredFormData={formData.mailform}/>
      // Add more cases as needed
      default:
        return "Unknown stepIndex";
    }
  };


  // Calculation Part Begin
  useEffect(() => {
    if (
      formData.selectedRackType !== null &&
      formData.selectedCarcaseType !== null &&
      formData.selectedDoorFinishType !== null &&
      formData.usermeasurements !== null &&
      formData.adminmeasurements !== null
    ) {
      const {
        selectedRackType,
        selectedCarcaseType,
        selectedDoorFinishType,
        adminmeasurements,
      } = formData;

      const subtotal =
        selectedRackType.price +
        selectedDoorFinishType.doorfinishprice +
        selectedDoorFinishType.carcasetype[0].price;

      // Calculate total cost
      const calculatedTotalCost =
        subtotal *
        selectedCarcaseType.Price *
        adminmeasurements.width *
        adminmeasurements.depth *
        adminmeasurements.height;

      // console.log('Total Estimate Cost:', calculatedTotalCost);
      setTotalCost(calculatedTotalCost);
    } else {
      // Reset totalCost if the condition is not met
      setTotalCost(null);
    }
  }, [formData]); 
  

  


// Calculation Part Ends 
  
  return (
    <div>
      <header className="flex">
        <div className="left">
          <div className="logo">
            <img src="https://venusinteriors.ideassion.in/wp-content/uploads/2023/09/logo-4.png" />
          </div>
        </div>
        <div className="center">
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step key="Step1">
              <StepLabel>Shoe Rack Type</StepLabel>
            </Step>
            <Step key="Step2">
              <StepLabel>Material</StepLabel>
            </Step>
            <Step key="Step3">
              <StepLabel>Frame Type</StepLabel>
            </Step>
            <Step key="Step4">
              <StepLabel>Get Quote</StepLabel>
            </Step>
            {/* Add more steps as needed */}
          </Stepper>
        </div>
        <div className="right">
          <p>
            {activeStep+1}/{totalStep}
          </p>
        </div>
      </header>
      <div className="center-body">
        <div className="center-card">
        {getStepContent(activeStep)}
        <p>Total Cost {totalCost}</p>
        </div>
        <div className="stepper-btn-container">
          <Button disabled={activeStep === 0} onClick={handleBack}>
            {/* {JSON.stringify(formData, null, 2)} */}
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={activeStep==3?handlesubmit:handleNext} disabled={ activeStep === 0 && formData.selectedRackType === null || activeStep === 1 && formData.selectedDoorFinishType ===null || (activeStep ===2 && formData.selectedCarcaseType ===null && formData.usermeasurements ===null) || activeStep ===3 && formData.mailform ===null} sx={{
            backgroundColor:(activeStep === 0 && formData.selectedRackType === null) || (activeStep === 1 && formData.selectedDoorFinishType ===null) || (activeStep ===2 && formData.selectedCarcaseType ===null && formData.usermeasurements ===null) ||(activeStep ===3 && formData.mailform ===null) ? "white":"#704c52", '&:hover': { backgroundColor: '#513136' },
          }}>
            {activeStep >= 3 ? "Get My Estimate" : "Next"}
          </Button>
        </div>
        <Modal open={openModal} onClose={handleCloseModal}>
          <div className="modal-box">
            <div className="modal-header">
              <h5 className="modal-title">Why it matters ?</h5>
              <CloseIcon
                className="close-icon"
                onClick={() => handleCloseModal()}
              />
            </div>
            <div className="modal-body">
              <p className="modal-description">
                The configuration and size of your home gives us a better idea
                of the magnitude of the project and helps us calculate the full
                home interior cost.
              </p>
            </div>
          </div>
        </Modal>
     
      </div>
      {/* <Container>
      <div>
        <Typography>{getStepContent(activeStep)}</Typography>
        <div>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === 4 ? 'Get My Estimate' : 'Next'}
          </Button>
        </div>
      </div>
    </Container> */}
    </div>
  );
};

export default ShoerackQuote;
