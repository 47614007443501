import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Container,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  FormControl,
  Card,
  CardContent,
} from "@mui/material";
import "./style.css";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CssBaseline from "@mui/material/CssBaseline";
import KitchenLayout from "./Components/Step2";
import KitchenType from "./Components/Step1";
import MaterialType from "./Components/Step3";
import FrameType from "./Components/Step4";
import GetEstimate from "./Components/Step5";
import { PartyMode } from "@mui/icons-material";
import VenusLogo from "../../assets/venuslogo.svg";


const KitchenQuote = () => {
  const [activeStep, setActiveStep] = useState(0);
  const totalStep = 5;
  const [openModal, setOpenModal] = useState(false);
  // const [selectedKitchenType, setSelectedkitchenType] = useState(null);
  const [formData,setFormData]=useState({
    selectedKitchenLayout:null,
    selectedKitchenType:null,
    selectedMaterialType:null,
    selectedFrameType:null,
    usermeasurements:null,
    adminmeasurements:null,
    mailform:null,
  })

  const [totalCost,setTotalCost]=useState(0)
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleKitchenTypeChange = (KitchenType)=>{
    setFormData((prevData)=>({
    ...prevData,
    selectedKitchenType:KitchenType
    }))
  }
  const handleKitchenLayoutChange = (kitchenLayoutType) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedKitchenLayout: kitchenLayoutType,
    }));
  };
  const handleMaterialLayoutChange = (MaterialType) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedMaterialType: MaterialType,
    }));
  };
  const handleFrameChange = (FrameType) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedFrameType: FrameType,
    }));
  };
  const handleFormMail= (form) => {
    setFormData((prevData) => ({
      ...prevData,
      mailform: form,
    }));
  };
  const handleUserMeasurements = (userM)=>{
    setFormData((prevData)=>({
      ...prevData,
     usermeasurements:userM
    }))
  }
  const handleAdminMeasurements = (adminM)=>{
    setFormData((prevData)=>({
      ...prevData,
     adminmeasurements:adminM
    }))
  }
  const handlesubmit=()=>{
    console.log(formData)
  }

  const bhkdata = [
    {
      id: 1,
      title: "1 BHK",
    },
    {
      id: 2,
      title: "2 BHK",
      categories: [
        {
          name: "Small",
          squarefeet: "800 Sqft",
        },
        {
          name: "Above",
          squarefeet: "800 Sqft",
        },
      ],
    },
    {
      id: 3,
      title: "3 BHK",
      categories: [
        {
          name: "Small",
          squarefeet: "800 Sqft",
        },
        {
          name: "Above",
          squarefeet: "800 Sqft",
        },
      ],
    },
  ];

  console.log(formData,'hey')


  
  const getStepContent = (stepIndex) => {
    // Define your form components for each step
    switch (stepIndex) {
      case 0:
        return <KitchenType onSelectKitchenType={handleKitchenTypeChange} openModal={handleOpenModal} selectedKitchenType={formData.selectedKitchenType}/>;
      case 1:
        return <KitchenLayout onSelectKitchenLayout={handleKitchenLayoutChange}  openModal={handleOpenModal} selectedKitchenLayout={formData.selectedKitchenLayout} selectedKitchenType={formData.selectedKitchenType} />;
      case 2:
        return <MaterialType onSelectMaterialType={handleMaterialLayoutChange} openModal={handleOpenModal} selectedMaterialType={formData.selectedMaterialType}/>
      case 3:
        return <FrameType onSelectFrameType={handleFrameChange} ons openModal={handleOpenModal} selectedFrameType={formData.selectedFrameType} onSelectUserMeasureMents={handleUserMeasurements} selectedUserM={formData.usermeasurements} onSelectAdminMeasurements={handleAdminMeasurements} selectedAdminM={formData.adminmeasurements} selectedKitchenType={formData.selectedKitchenType}/>
      case 4  :
        return <GetEstimate OnSendFormData={handleFormMail} EnteredFormData={formData.mailform}/>
      // Add more cases as needed
      default:
        return "Unknown stepIndex";
    }
  };


  // Calculation Part Begin
  useEffect(() => {
    if (
      formData.selectedKitchenType !== null &&
      formData.selectedKitchenLayout !== null &&
      formData.selectedMaterialType !== null &&
      formData.selectedFrameType !== null &&
      formData.usermeasurements !== null &&
      formData.adminmeasurements !== null
    ) {
      const {
        selectedKitchenLayout,
        selectedMaterialType,
        selectedFrameType,
        adminmeasurements,
      } = formData;

      const subtotal =
        selectedKitchenLayout.Price +
        selectedMaterialType.doorfinishprice +
        selectedMaterialType.carcasetype[0].price;

      // Calculate total cost
      const calculatedTotalCost =
        subtotal *
        selectedFrameType.Price *
        adminmeasurements.width *
        adminmeasurements.depth *
        adminmeasurements.height;

      // console.log('Total Estimate Cost:', calculatedTotalCost);
      setTotalCost(calculatedTotalCost);
    } else {
      // Reset totalCost if the condition is not met
      setTotalCost(null);
    }
  }, [formData]); 
  

  


// Calculation Part Ends 
  
  return (
    <div>
      <header className="flex">
        <div className="left">
          <div className="logo">
            <img src="https://venusinteriors.ideassion.in/wp-content/uploads/2023/09/logo-4.png" />
          </div>
        </div>
        <div className="center">
          <Stepper activeStep={activeStep} alternativeLabel style={{color:'red'}} >
            <Step key="Step1">
              <StepLabel className="step-label">Kitchen Type</StepLabel>
            </Step>
            <Step key="Step2">
              <StepLabel>Kitchen Layout</StepLabel>
            </Step>
            <Step key="Step3">
              <StepLabel>Material</StepLabel>
            </Step>
            <Step key="Step4">
              <StepLabel>Frame Type</StepLabel>
            </Step>
            <Step key="Step5">
              <StepLabel>Get Quote</StepLabel>
            </Step>
            {/* Add more steps as needed */}
          </Stepper>
        </div>
        <div className="right">
          <p>
            {activeStep+1}/{totalStep}
          </p>
        </div>
      </header>
      <div className="center-body">
        <div className="center-card">
        {getStepContent(activeStep)}
        <p>Total Cost {totalCost}</p>
        </div>
        <div className="stepper-btn-container">
          <Button disabled={activeStep === 0} onClick={handleBack}>
            {/* {JSON.stringify(formData, null, 2)} */}
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={activeStep==4?handlesubmit:handleNext} disabled={ (activeStep === 0 && formData.selectedKitchenType === null) || (activeStep === 1 && formData.selectedKitchenLayout ===null) || (activeStep ===2 && formData.selectedMaterialType ===null) || ((activeStep ===3 && formData.selectedFrameType === null) && (activeStep ===3 && formData.adminmeasurements === null)) || activeStep ===4 && formData.mailform ===null} sx={{
            backgroundColor:(activeStep === 0 && formData.selectedKitchenType === null) || (activeStep === 1 && formData.selectedKitchenLayout ===null) || (activeStep ===2 && formData.selectedMaterialType ===null) || ((activeStep ===3 && formData.selectedFrameType === null) && formData.adminmeasurements===null ) || (activeStep ===4 && formData.mailform === null) ? "white":"#704c52", '&:hover': { backgroundColor: '#513136' },
          }}>
            {activeStep >= 4 ? "Get My Estimate" : "Next"}
          </Button>
        </div>
        <Modal open={openModal} onClose={handleCloseModal}>
          <div className="modal-box">
            <div className="modal-header">
              <h5 className="modal-title">Why it matters ?</h5>
              <CloseIcon
                className="close-icon"
                onClick={() => handleCloseModal()}
              />
            </div>
            <div className="modal-body">
              <p className="modal-description">
                The configuration and size of your home gives us a better idea
                of the magnitude of the project and helps us calculate the full
                home interior cost.
              </p>
            </div>
          </div>
        </Modal>
     
      </div>
      {/* <Container>
      <div>
        <Typography>{getStepContent(activeStep)}</Typography>
        <div>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === 4 ? 'Get My Estimate' : 'Next'}
          </Button>
        </div>
      </div>
    </Container> */}
    </div>
  );
};

export default KitchenQuote;
