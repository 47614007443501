import React,{useEffect, useState} from "react";
import {
    Radio,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    FormControl,
    Card,
    CardContent,
    Typography,
    InputLabel,
    Select,
    MenuItem,
  } from "@mui/material";




const CarcaseMaterial = ({ onSelectMaterialType, openModal,selectedMaterialType,onSelectUserMeasureMents,onSelectAdminMeasurements,selectedUserM,selectedAdminM }) => {
  const [usermeasurementData,setUserMeasurementData]=useState({
    width:selectedUserM!==null ? selectedUserM.width:"",
    depth:selectedUserM!==null ? selectedUserM.depth:"",
    height:selectedUserM!==null ? selectedUserM.height:"",
  });
  const[adminsideMeasurementData,setAdminSideMeasurementData]=useState({
    width:selectedAdminM!==null?selectedAdminM.width:"",
    depth:selectedAdminM!==null?selectedAdminM.depth:"",
    height:selectedAdminM!==null?selectedAdminM.height:"",

  })



    const data = [
      { name: "Particle Board", Price: 0.6 },
      { name: "HDF", Price: 1.4 },
      { name: "MR Ply Wood  ", Price: 1 },
      { name: "BWR Ply wood ", Price: 1.8 },
    ];
    const [value, setValue] = useState(selectedMaterialType? selectedMaterialType.name : "");

    const handleChange = (event) => {
      setValue(event.target.value);
      const selectedmaterial = data.find(
        (item) => item.name === event.target.value
      );
     
      onSelectMaterialType(selectedmaterial)
    };

      useEffect(()=>{
        onSelectUserMeasureMents(usermeasurementData)
        onSelectAdminMeasurements(adminsideMeasurementData)


      },[usermeasurementData,adminsideMeasurementData])
      
    
    // Generate width options from 15 to 96
    const widthOptions = Array.from({ length: 82 }, (_, index) => 15 + index);
    
  // Generate depth options from 12 to 23
  const depthOptions = Array.from({ length: 12 }, (_, index) => 12 + index);


    // Generate height options from 70 to 126
    const heightOptions = Array.from({ length: 57 }, (_, index) => 70 + index);

    const handleMeasurements =(fieldName)=>(event)=>{
      const {value}=event.target;
      if(fieldName==="width" && value){
        const numericValue = parseFloat(value)
        if (numericValue >= 15 && numericValue <= 18) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 1.5,
          }));
        } 
        if (numericValue >= 19 && numericValue <= 24) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 2,
          }));
        } 
        if (numericValue >= 25 && numericValue <= 30) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 2.5,
          }));
        } 
        if (numericValue >= 31 && numericValue <= 36) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 3,
          }));
        } 
        if (numericValue >= 37 && numericValue <= 42) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 3.5,
          }));
        } 
        if (numericValue >= 43 && numericValue <= 48) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 4,
          }));
        } 
        if (numericValue >= 49 && numericValue <= 54) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 4.5,
          }));
        } 
        if (numericValue >= 55 && numericValue <= 60) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 5,
          }));
        } 
        if (numericValue >= 61 && numericValue <= 66) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 5.5,
          }));
        } 
        if (numericValue >= 67 && numericValue <= 72) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 6,
          }));
        } 
        if (numericValue >= 73 && numericValue <= 78) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 6.5,
          }));
        } 
        if (numericValue >= 79 && numericValue <= 84) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 7,
          }));
        } 
        if (numericValue >= 85 && numericValue <= 90) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 7.5,
          }));
        } 
        if (numericValue >= 91 && numericValue <= 96) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 8,
          }));
        } 
         
      }
      if(fieldName==="depth"&&value){
        const numericValue = parseFloat(value)
        if (numericValue <= 12) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 1.00,
          }));
        } 
        if (numericValue >= 13 && numericValue <= 18) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 1.50,
          }));
        } 
        if (numericValue >= 19 && numericValue <= 23) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 2.00,
          }));
        } 
      }
      if(fieldName==="height"&&value){
        const numericValue = parseFloat(value)
        if (numericValue >= 71 && numericValue <= 72) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 5,
          }));
        } 
        if (numericValue >= 73 && numericValue <= 78) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 5.5,
          }));
        } 
        if (numericValue >= 79 && numericValue <= 84) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 6,
          }));
        } 
        if (numericValue >= 85 && numericValue <= 90) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 6.5,
          }));
        } 
        if (numericValue >= 91 && numericValue <= 96) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 7,
          }));
        } 
        if (numericValue >= 97 && numericValue <= 102) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 7.5,
          }));
        } 
        if (numericValue >= 103 && numericValue <= 108) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 8,
          }));
        } 
        if (numericValue >= 109 && numericValue <= 114) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 8.5,
          }));
        } 
        if (numericValue >= 115 && numericValue <= 120) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 9,
          }));
        } 
        if (numericValue >= 121 && numericValue <= 126) {
          setAdminSideMeasurementData((prevdata) => ({
            ...prevdata,
            [fieldName]: 9.5,
          }));
        } 
      }
      
        setUserMeasurementData((prevdata)=>({
          ...prevdata,[fieldName]: value
        }))

      
      
      

     

    }

    console.log(adminsideMeasurementData.width,"getting admin width")
    // console.log(usermeasurementData.width,"getting user width")
    console.log(adminsideMeasurementData.depth,"getting admin depth")
    console.log(adminsideMeasurementData.height,"getting admin height")

    // const handleChange = (fieldName) => (event) => {
    //   const { value } = event.target;
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     [fieldName]: value,
    //   }));
    // };

    return (
      <div className="quote-step">
        <p className="title">Select Your Frame Type</p>
        <p>
          To know more about this,
          <span onClick={() => openModal()} className="click">
            Click here
          </span>
        </p>
        <FormControl >
        <Typography sx={{mb:"10px",fontWeight:"bold",textAlign:"center"}}>Select Your Carcase Material </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
            className="radio-container"
            
            sx={
                {
                    display:"flex",
                    flexDirection:"row",
                    gap:5,
                    alignItems:'center',
                    justifyContent:'center',
                }
                
            }
          >
            {data.map((item, index) => (
              <Card
                key={index}
                sx={{
                  mb: "20px",
                  minWidth: "100px",
                  maxWidth:"200px",
                  padding: "10px",
                  border:
                    value === item.name
                      ? "2px solid #704c52"
                      : "2px solid transparent",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "#704c52",
                  },
                }}
                // onClick={() => handleChange({ target: { value: item.name } })}
              >
                <FormControlLabel
                  sx={{ width: "300px" }}
                  control={<Radio  sx={{
          color: "#704c52",
          '&.Mui-checked': {
            color: "#704c52",
          },
        }}/>}
                  value={item.name}
                  label={item.name}
                />
              </Card>
            ))}
          </RadioGroup>
       
        </FormControl>
        <Typography sx={{mb:"10px",fontWeight:"bold"}}>Select Your Shoe Rack Dimensions</Typography>
        <div style={{display:'flex',gap:"20px",marginTop:"70px"}} className="drop-down-container">
       

        <FormControl sx={{display:"flex",flexDirection:"row"}} fullWidth>
        
          <InputLabel id="demo-simple-select-label">Width</InputLabel>
        <Select

          className="drop-down"
          value={usermeasurementData.width}
          label="Width"
          type="width"
          onChange={handleMeasurements("width")}
          sx={{width:"150px"}}
        >
        {
          widthOptions.map((obj,index)=>(
            <MenuItem value={obj} key={index}>{obj}</MenuItem>
          ))
        }
        
        </Select>
          
        </FormControl>
        <FormControl sx={{display:"flex",flexDirection:"row"}} fullWidth>
        
          <InputLabel id="demo-simple-select-label">Depth</InputLabel>
        <Select
           className="drop-down"
       
          value={usermeasurementData.depth}
          label="Depth"
          type="depth"
          onChange={handleMeasurements("depth")}
          sx={{width:"150px"}}
        >
        {
          
          depthOptions.map((obj,index)=>(
            <MenuItem value={obj} key={index}>{obj}</MenuItem>
          ))
        }
        
        </Select>
          
        </FormControl>
        <FormControl sx={{display:"flex",flexDirection:"row"}} fullWidth>
        
          <InputLabel id="demo-simple-select-label">Height</InputLabel>
        <Select
        className="drop-down"
          value={usermeasurementData.height}
          label="Height"
          type="height"
          onChange={handleMeasurements("height")}
          sx={{width:"150px"}}
        >
        {
          heightOptions.map((obj,index)=>(
            <MenuItem value={obj} key={index}>{obj}</MenuItem>
          ))
        }
        
        </Select>
          
        </FormControl>
        
        

        </div>
        
      </div>
    );
  };


  export default CarcaseMaterial