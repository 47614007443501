import React,{useState,useEffect} from "react";
import {
    Radio,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    FormControl,
    Card,
    CardContent,
  } from "@mui/material";
import { pink } from "@mui/material/colors";



const KitchenLayout = ({ onSelectKitchenLayout, openModal,selectedKitchenLayout,selectedKitchenType }) => {


  const KitchenBottomdata = [
    { name: "Parallel Kitchen", Price: 1450 },
    { name: "Straight Kitchen", Price: 1450 },
    { name: "L Shape Kitchen", Price: 1450 },
    { name: "U Shape Kitchen", Price: 1450 },
    { name: "Island Kitchen", Price: 2100 },
    { name: "Tall Unit", Price: 1450 },
  ];
  
  const KitchenTopData = [
    { name: "Parallel Kitchen", Price: 1250 },
    { name: "Straight Kitchen", Price: 1250 },
    { name: "L Shape Kitchen", Price: 1250 },
    { name: "U Shape Kitchen", Price: 1250 },
    { name: "Island Kitchen", Price: 2100 },
  ];
  const [data, setData] = useState(KitchenTopData);

  // console.log(data)

  useEffect(() => {
    // Update data based on the selectedKitchenType
    if (selectedKitchenType && selectedKitchenType.name === "Kitchen Bottom") {
      setData(KitchenBottomdata);
    } else {
      setData(KitchenTopData);
    }
  }, [selectedKitchenType]);

    const [value, setValue] = useState(selectedKitchenLayout? selectedKitchenLayout.name : "");

    const handleChange = (event) => {
      setValue(event.target.value);
      const selectedKitchen = data.find(
        (item) => item.name === event.target.value
      );

      onSelectKitchenLayout(selectedKitchen)
    };

    return (
      <div className="quote-step">
        <p className="title">Select Your Kitchen Layout</p>
        <p>
          To know more about this,
          <span onClick={() => openModal()} className="click">
            Click here
          </span>
        </p>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            {data.map((item, index) => (
              <Card
                key={index}
                sx={{
                  mb: "20px",
                  minWidth: "300px",
                  maxWidth:"300px",
                  padding: "10px",
                  border:
                    value === item.name
                      ? "2px solid #704c52"
                      : "2px solid transparent",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "#704c52",
                  },
                }}
                // onClick={() => handleChange({ target: { value: item.name } })}
              >
                <FormControlLabel
                  sx={{ width: "300px" }}
                  control={<Radio  sx={{
          color: "#704c52",
          '&.Mui-checked': {
            color: "#704c52",
          },
        }}/>}
                  value={item.name}
                  label={item.name}
                />
              </Card>
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    );
  };


  export default KitchenLayout