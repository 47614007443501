import logo from './logo.svg';
import './App.css';
import KitchenQuote from './Screens/KitchenQuote';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import ShoerackQuote from './Screens/ShoerackQuote';
import WardrobeQuote from './Screens/WardrobeQuote';
import { SnackbarProvider } from 'notistack';
function App() {
  return (
    <SnackbarProvider maxSnack={3}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<KitchenQuote/>}/>
        <Route path='shoerack-quote' element={<ShoerackQuote/>}/>
        <Route path='wardrobe-quote' element={<WardrobeQuote/>}/>
      </Routes>
    </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
